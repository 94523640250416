import React, {useState, useEffect, useRef} from 'react';
import useAuth from './hooks/useAuth';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link, Navigate } from 'react-router-dom';
import axios from './api/axios'
import CreateLocation from './components/CreateLocation'
import Login from './components/Login'
import Registration from './components/Registration'
import FrontPage from './components/FrontPage'
import Post from "./components/Post";
import Location from "./components/Location";
import PageNotFound from "./components/PageNotFound";
import Profile from "./components/Profile";
import ChangePassword from "./components/ChangePassword";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Modal from './components/Modal'
import MapModal from './components/MapModal'
import MobileNavigation from './components/MobileNavigation'
import Navigation from './components/Navigation'
import PersistLogin from "./components/PersistLogin";
import ContentPolicy from "./components/ContentPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import UserAgreement from "./components/UserAgreement";
import Contact from "./components/Contact";
import VerifyEmail from "./components/VerifyEmail";
import ScrollToTop from "./components/ScrollToTop";
import ModalMapBasic from './components/ModalMapBasic'

const BUTTON_WRAPPER_STYLES = {
  position: 'relative',
  zIndex: 1
}

const OTHER_CONTENT_STYLES = {
  position: 'relative',
  zIndex: 2,
  backgroundColor: 'lightgrey',
  padding: '10px'
}

function App() {

  const [isOpen, setIsOpen] = useState(false)
  const [isMapOpen, setIsMapOpen] = useState(false)

  const [mapCenter, setMapCenter] = useState([40.53459, -3.69723])
  const [mapZoom, setMapZoom] = useState(2)

  if(isOpen || isMapOpen) {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }

  /////

  const [locationsList, setLocationsList] = useState([])

  const getLocations = () => {
    axios.get("/locations").then((response) => {
      setLocationsList(response.data);
    });
  };

  ////

  const { auth } = useAuth();

  const [authState, setAuthState] = useState({
    username: "",
    id: 0,
    status: false,
  });

  useEffect(() => {

      getLocations()

  }, []);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({ username: "", id: 0, status: false });
  };

  return (

    <>

        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          {!auth.status ? <Login setIsOpen={setIsOpen}/> : <div>you are logged in<br /><br /></div>}
        </Modal>

        <MapModal open={isMapOpen} onClose={() => setIsMapOpen(false)} mapCenter={mapCenter} mapZoom={mapZoom}>

        </MapModal>

    <div className="App" style={OTHER_CONTENT_STYLES}>

      <div className="mobileView">

        <MobileNavigation
          setMapZoom = {setMapZoom}
          setMapCenter={setMapCenter}
          setIsMapOpen={setIsMapOpen}
          setIsOpen={setIsOpen}
          locationsList={locationsList}
        />

      </div>

      <div className="desktopView">

        <Navigation
          setMapZoom = {setMapZoom}
          setMapCenter={setMapCenter}
          setIsMapOpen={setIsMapOpen}
          setIsOpen={setIsOpen}
          locationsList={locationsList}
        />

      </div>

      <ScrollToTop />
      <Routes>

        <Route element={<PersistLogin />}>

          <Route path ='/' element={<FrontPage locationsList={locationsList} />}/>
          <Route path ='/contactus' element={<Contact />} />
          <Route path ='/contentpolicy' element={<ContentPolicy />} />
          <Route path ='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path ='/useragreement' element={<UserAgreement />} />
          <Route path ='/createlocation' element={<CreateLocation setIsOpen={setIsOpen} />} />
          <Route path ='/changepassword' element={<ChangePassword />} />
          <Route path ='/forgotpassword' element={<ForgotPassword />} />
          <Route path ='/register' element={<Registration setIsOpen={setIsOpen} />} />
          <Route path="/profile/:id" exact element={<Profile />} />
          <Route path="/post/:id" exact element={<Post />} />
          <Route path="/location/:id" exact element={<Location setIsOpen={setIsOpen} setMapCenter={setMapCenter} setIsMapOpen={setIsMapOpen} setMapZoom = {setMapZoom} />} />
          <Route path="/user/:name/verify/:verifyToken" exact element={<VerifyEmail setIsOpen={setIsOpen} />} />
          <Route path="/user/pass/reset/:verifyToken" exact element={<ResetPassword setIsOpen={setIsOpen} />} />
          <Route path='*' element={<PageNotFound status={404}/>} />

        </Route>

      </Routes>

    </div>
    </>

  );
}

export default App;
