import React from 'react';
import L from 'leaflet';
import 'leaflet-control-geocoder'
import 'leaflet.locatecontrol'

class ModalMapBasic extends React.Component {

  constructor(props){
    super(props)
    this.state ={
      parksList:this.props.parksList,
      spotsList:this.props.spotsList,
      center: this.props.mapCenter,
      zoom: this.props.mapZoom
    }
  }

  componentDidMount(){

    if(this.map!=undefined){this.map.remove()}

    // create map
    this.map =  L.map('map', {
      center: this.state.center,
      zoom: this.state.zoom,
      layers: [
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }),
      ]
    });

    var spotIcon = new L.Icon({
      iconUrl: require('../assets/spot.png'),
      iconSize:     [25,25], // size of the icon
      iconAnchor:   [12.5, 25], // point of the icon which will correspond to marker's location
      popupAnchor:  [0, -20] // point from which the popup should open relative to the iconAnchor
    });

    var parkIcon = new L.Icon({
      iconUrl: require('../assets/park.png'),
      iconSize:     [25,25], // size of the icon
      iconAnchor:   [12.5, 25], // point of the icon which will correspond to marker's location
      popupAnchor:  [0, -20] // point from which the popup should open relative to the iconAnchor
    });

        // add marker
    //this.marker = L.marker([38.9072, -77.0369]).bindPopup('YO YO DC').addTo(this.map)
    // add markers layer
    this.layer = L.layerGroup().addTo(this.map);
    // search
    this.control = L.Control.geocoder().addTo(this.map);
    // geo location
    this.control = L.control.locate({
                  position: 'topright',
                  strings: {
                      title: "Show me where I am, yo!"
                  }
              }).addTo(this.map);

    //add spots
    for (var i = 0; i < this.state.spotsList.length; i++) {
        var item = this.state.spotsList[i];

        this.marker = new L.marker([item.latitude,item.longitude],
          {icon:spotIcon}
        ).bindPopup(`<a href="/location/${item.id}">${item.locationName}</a><br/>
                    ${item.locationDesc}<br />
                    added by: <a href="/profile/${item.UserId}">${item.username}</a>
                  `)
        .addTo(this.map);
    }

    //add parks
    for (var i = 0; i < this.state.parksList.length; i++) {
        var item = this.state.parksList[i];

        this.marker = new L.marker([item.latitude,item.longitude],
          {icon:parkIcon}
        ).bindPopup(`<a href="/location/${item.id}">${item.locationName}</a><br/>
                    ${item.locationDesc =='coming soon...' ? 'desc. '+ item.locationDesc:
                      item.locationDesc
                    } <br />
                    added by: <a href="/profile/${item.UserId}">${item.username}</a>
                  `)
        .addTo(this.map);
    }

  }

  render() {

    return(
      <div id="map" style={{ height:'90vh', width:'80vw'}}></div>
    )
  }

}

export default ModalMapBasic
