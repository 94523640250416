import React, { useState } from "react";
import axios from '../api/axios'
import useAuth from '../hooks/useAuth';
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const { auth, persist } = useAuth();

  let history = useNavigate();

  const changePassword = () => {
    if(!auth.id){
      alert('must sign in')
      return false
    }
    else if(newPassword.length <8){
      alert('password must be at least 8 characters')
      return false
    }
    else if(oldPassword == newPassword){
      alert('new password must be different from old password')
      return false
    }
    else if(newPassword != newPasswordConfirm){
      alert('your new password & confirmation must match')
      return false
    }
    axios
      .put(
        "/auth/changepassword",
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            accessToken: auth.accessToken
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
          history('/changepassword')
        } else {
          alert('password changed')
          history(`/profile/${auth.id}`)
        }
      });
  };

  return (
    <div className="password">
      <h1>Change Your Password</h1>
      <input
        type="password"
        placeholder="Old Password..."
        onChange={(event) => {
          setOldPassword(event.target.value);
        }}
      /> <br /><br />
      <input
        type="password"
        placeholder="New Password..."
        onChange={(event) => {
          setNewPassword(event.target.value);
        }}
      /> <br /><br />
      <input
        type="password"
        placeholder="Confirm New Password..."
        onChange={(event) => {
          setNewPasswordConfirm(event.target.value);
        }}
      /> <br /><br />
      <button onClick={changePassword} className="loginButton"> SAVE CHANGES</button>
    </div>
  );
}

export default ChangePassword;
