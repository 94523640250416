import React from 'react';
import '../css/Policy.css'

const ContentPolicy = ()=>{
  return(
    <div className="policyHolder">
      <h1 id="contentPolicyTop">Open Skateboarding Map(OSM) <br />Content Policy</h1>

      <p>
        OSM aims to serve as a living archive for the progression of skateboarding culture.
      </p>

      <p>
        It is what we make it together, and can only exist if we operate by a shared set of rules. We ask that you abide by not just the letter of these rules, but the spirit as well.
      </p>

      <p>
        JUST BE CHILL.
      </p>

      <h2>Rules</h2>

      <h3>Rule 1</h3>
      <p>
        Remember the human. OSM is a place for creating community and belonging, not for attacking marginalized or vulnerable groups of people. Everyone has a right to use OSM free of harassment, bullying, and threats of violence.
      </p>

      <h3>Rule 2</h3>
      <p>
        Post only authentic content where you have a personal interest, and do not cheat or engage in content manipulation (including spamming, vote manipulation, ban evasion, or subscriber fraud) or otherwise interfere with or disrupt the OSM community.
      </p>

      <h3>Rule 3</h3>
      <p>
        Respect the privacy of others. Instigating harassment, for example by revealing someone’s personal or confidential information, is not allowed. Never post or threaten to post intimate or sexually-explicit media of someone without their consent.
      </p>

      <h3>Rule 4</h3>
      <p>
        Do not post or encourage the posting of sexual or suggestive content involving minors.
      </p>

      <h3>Rule 5</h3>
      <p>
        You don’t have to use your real name to use OSM, but don’t impersonate an individual or an entity in a misleading or deceptive manner.
      </p>

      <h3>Rule 6</h3>
      <p>
        Ensure people have predictable experiences on OSM by properly labeling content, particularly content that is graphic, sexually-explicit, or offensive.
      </p>

      <h3>Rule 7</h3>
      <p>
        Keep it legal, and avoid posting illegal content or soliciting or facilitating illegal or prohibited transactions.
      </p>

      <h3>Rule 8</h3>
      <p>
        Don’t break the site or do anything that interferes with normal use of OSM.
      </p>

      <h2>Enforcement</h2>

      <p>
        We have a variety of ways of enforcing our rules, including, but not limited to:
      </p>

      <ul>
        <li>
          Asking you nicely to knock it off
        </li>
        <li>
          Asking you less nicely
        </li>
        <li>
          Temporary or permanent suspension of accounts
        </li>
        <li>
          Removal of privileges from, or adding restrictions to, accounts
        </li>
        <li>
          Removal of content
        </li>
        <li>
          Banning from OSM
        </li>
      </ul>

      <br />
      <p onClick={()=>window.scrollTo(0, 0)}>
        BACK TO TOP
      </p>

    </div>
  )

}

export default ContentPolicy
