import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from '../api/axios'
import useAuth from '../hooks/useAuth';
import '../css/Post.css'

const Post =()=>{

  const { auth, setAuth } = useAuth();
  let { id } = useParams();

  const history = useNavigate()

  let [newPostTitle, setNewPostTitle] = useState("");
  let [newPostText, setNewPostText] = useState("");
  let [newPostLink, setNewPostLink] = useState("");

  const validateEdit = () => {
    if(newPostTitle ==='' || !typeof newPostTitle ==='string'){
      newPostTitle=postObject.postTitle
    }
    if(newPostText ==='' || !typeof newPostText ==='string'){
      newPostText=postObject.postText
    }
    if(newPostLink ==='' || !typeof newPostLink ==='string'){
      newPostLink=postObject.postLink
    }

    return true
  }

  const sendPostEdits = (e)=>{
    e.preventDefault()
    const isValid = validateEdit()
    if(isValid){
      editPostTest()
    }
  }

  const editPost = () =>{
    if(auth.id === 0 || auth.username === ""){
      alert('must sign in')
    } else {
      axios.put("/posts/editPost",{
        headers: {
          accessToken: auth.accessToken
        },
        newPostTitle: newPostTitle,
        newPostText: newPostText,
        newPostLink: newPostLink,
        id: id,
      }).then((response)=>{
        //used to console
      })
    }
  }

  const editPostTest = () => {
    axios
      .put(
        "/posts/editPost",
        {
          newPostTitle: newPostTitle,
          newPostText: newPostText,
          newPostLink: newPostLink,
          id: id,
        },
        {
          headers: {
            accessToken: auth.accessToken
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert('must be logged in to edit post')
        } else {
          const locationToEdit = {
            newPostTitle: newPostTitle,
            newPostText: newPostText,
            newPostLink: newPostLink,
            id: id,
          };
          alert('post edited')
          getPost()
        }
      });
  };

  const [postObject, setPostObject] = useState({});
  const [createDate, setCreateDate] = useState('')
  const [postLikes, setPostLikes] = useState('')
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [createDates, setCreateDates] = useState([])

  const [displayPostTitle, setDisplayPostTitle] =useState('')

  const [newCommentError, setNewCommentError] = useState("");

  const validate = () => {
    let newCommentError =""

    if(newComment==="" || !typeof newComment ==='string'){
      newCommentError = "add text"
    }

    if(newCommentError) {
      setNewCommentError(newCommentError)
      return false
    }

    if (!auth.accessToken) {
      setNewCommentError("")
      alert('must be logged in comment')
      return false
    }

    return true
  }

  const handleNewComment = (event)=>{
    event.preventDefault()
    const isValid = validate()
    if(isValid){
      addComment()
      event.target.reset()
      setNewCommentError('')
    }
  }

  const addComment = () => {
    axios
      .post(
        "/comments",
        {
          commentBody: newComment,
          PostId: id,
          UserId: auth.id,
        },
        {
          headers: {
            accessToken: auth.accessToken
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error)
          setNewComment("");
        } else {
          const commentToAdd = {
            commentBody: newComment,
            username: response.data.username,
          };
          setComments([...comments, commentToAdd]);
          setNewComment("");
          getComments()
        }
      });
  };

  const deleteComment = (id) => {
    axios
      .delete(`/comments/${id}`, {
        headers: {
          accessToken: auth.accessToken },
        })
      .then(() => {
        setComments(
          comments.filter((val) => {
            return val.id != id;
          })
        );
      });
  };

  const deletePost = (id) => {
    axios
      .delete(`/posts/${id}`, {
        headers: {
          accessToken: auth.accessToken
        },
      })
      .then(() => {
        history(`/location/${postObject.LocationId}`);
      });
  };

  const getPost =()=>{
    axios.get(`/posts/byId/${id}`).then((response) => {
      try{

        setPostObject(response.data.post);
        setCreateDate(response.data.createDate)
        setPostLikes(response.data.postLikes)
        setDisplayPostTitle(response.data.post.postTitle)

      }catch(err){
        alert("post doesn't exist")
        history('/')
      }
    });
  }

  const getComments =()=>{
    axios.get(`/comments/${id}`).then((response) => {
      setComments(response.data.comments);
      setCreateDates(response.data.createDates)
    });
  }

  useEffect(() => {

    getPost()

    getComments()

  }, []);

  return(
    <div className="postWrapper">

      <div className="postOne">
        <h3>{displayPostTitle} </h3>
        {postObject.postText} <br />
            Location:{' '}
            <Link to={`/location/${postObject.LocationId}`}>
              {postObject.LocationId}
            </Link><br />
            posted by:
            <Link to={`/profile/${postObject.UserId}`}>
              {' '}{postObject.username}
            </Link><br />
             on {createDate} <br />
           {postLikes} {' '} likes

      </div>

      <div className="postTwo">

      { postObject.postLink ?
        <iframe
          className="postFrame"
          width="400" height="225"
          src={`https://www.youtube.com/embed/${postObject.postLink}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
          title="YouTube video player"
          allow="autoplay"
          frameBorder="0">
        </iframe> :

        '[ No media ]'
      }

      </div>

      <div className="postThree">

        {auth.id === postObject.UserId && (
          <div>
            <h3>Edit Post</h3>
            <form onSubmit={sendPostEdits}>
              <label>Title</label>:
              <input
                type="text"
                defaultValue={postObject.postTitle}
                onChange={(event) => {
                  setNewPostTitle(event.target.value);
                }}
              /><br /><br />
              <label>Text</label>:
              <input
                type="text"
                defaultValue={postObject.postText}
                onChange={(event) => {
                  setNewPostText(event.target.value);
                }}
              /><br /><br />
              <label>Link</label>:
              <input
                type="text"
                defaultValue={postObject.postLink}
                placeholder=" YouTube video string"
                onChange={(event) => {
                  setNewPostLink(event.target.value);
                }}
              /><br /><br />
            <input type="submit" className="loginButton" value="SEND EDITS" />
            </form> <br />

            <div>

              <button onClick={()=>{if(window.confirm('Do you really want to DELETE your POST?')){
                deletePost(postObject.id);
                alert('post deleted')
              }

              }} className="deleteCommentButton">
              {" "}
              DELETE POST
              </button>

            </div>
          </div>
        )}

        <br />

      </div>

      <div className ="postFour">

          <form onSubmit={handleNewComment}>
            <input
              type="text"
              placeholder="Comment..."
              autoComplete="off"
              value={newComment}
              onChange={(event) => {
                setNewComment(event.target.value);
              }}
            />
            <p style={{fontSize:16, color:'red'}}>{newCommentError}</p>

            <input type="submit" className="addCommentButton" value="ADD COMMENT" />
          </form>

          <div>
            {comments.map((comment, index) => {
              const createDate =createDates[index]
              return (
                <div style={{marginTop: "10px"}} key={index} className="comment">

                  <Link to={`/profile/${comment.UserId}`}>{comment.username}</Link>
                  {' '} wrote {' '}
                  "{comment.commentBody}" {' '}
                  on {comment.createdAt} {' '}

                  {auth.username === comment.username ?

                    <button onClick={()=>{if(window.confirm('Do you really want to DELETE your COMMENT?')){
                      deleteComment(comment.id);
                      alert('comment deleted')
                    }

                    }} className="deleteCommentButton">
                    {"  "}
                    DELETE COMMENT
                    </button>
                    :
                    ''
                  }

                </div>
              );
            })}
          </div>

      </div>

    </div>
  )
}

export default Post
