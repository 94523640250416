import ReactDom from 'react-dom'
import ModalMapBasic from './ModalMapBasic'
import axios from '../api/axios'
import React, { useState, useEffect } from 'react';

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '25px',
  zIndex: 1000,
  width:"80vw"
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000
}

function MapModal({ open, children, onClose, mapCenter, mapZoom }) {

  const [locationsList, setLocationsList] = useState([])
  const [parksList, setParksList] = useState([])
  const [spotsList, setSpotsList] = useState([])

  const getLocations = () => {
    axios.get("/locations").then((response) => {
      setLocationsList(response.data);
    });
  };

  const getParks = () => {
    axios.get("/locations/parks").then((response) => {
      setParksList(response.data);
    });
  };

  const getSpots = () => {
    axios.get("/locations/spots").then((response) => {
      setSpotsList(response.data);
    });
  };

  useEffect(() => {

      //getLocations()
      getSpots()
      getParks()

  }, []);

  if (!open) return null

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>
        <div style={{float:'right', fontSize:'1.5em'}}onClick={onClose}>x</div>
        {children}

        <ModalMapBasic
          parksList={parksList}
          spotsList={spotsList}
          mapCenter={mapCenter}
          mapZoom ={mapZoom}
        />

      </div>
    </>,
    document.getElementById('portal')
  )
}

export default MapModal
