import React, { useState } from "react";
import axios from '../api/axios'
import { useNavigate, Link } from "react-router-dom";
import useAuth from '../hooks/useAuth';

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { auth, setAuth } = useAuth();

  let history = useNavigate()

  const login = () => {
    const data = { username: username, password: password };
    axios.post("/auth/login", data,
      {
        withCredentials: true, credentials: 'include'
      }
     ).then((response) => {
      if (response.data.error) {
        alert(response.data.error);
      } else {
        const accessToken = response?.data?.accessToken;
        setAuth({
          username: response.data.username,
          id: response.data.id,
          status: true,
          accessToken
        });
      }
    });
  };

  return (
    <div className="loginContainer">
      <input
        type="text"
        placeholder="username"
        onChange={(event) => {
          setUsername(event.target.value);
        }}
      /> <br /><br />
      <input
        type="password"
        placeholder="password"
        onChange={(event) => {
          setPassword(event.target.value);
        }}
      /> <br /><br />

      <button onClick={login} className='loginButton'> LOGIN </button> <br />

      <Link to='/forgotpassword'>
      <p style={{color:'black'}} onClick ={()=>props.setIsOpen(false)}>forgot your
      password <br />or username?</p>
      </Link>

    </div>
  );
}

export default Login;
