import React from 'react';
import useLogout from '../hooks/useLogout'

const Logout = ()=>{

  const logout = useLogout()

  const signout = () => {
    logout()
  };

  return(

    <button className="logoutButton" onClick={signout}> LOGOUT</button>

  )
}

export default Logout
