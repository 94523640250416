import React, {useState} from 'react'
import useAuth from '../hooks/useAuth'
import { Link } from 'react-router-dom'
import logo from '../assets/openSkateboardingMapLogo.png'
import Logout from './Logout'
import '../css/Navigation.css'
import SearchBar from './SearchBar'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const MobileNavigationItems =(props)=>{

  const { auth } = useAuth();

  const [showLinks, setShowLinks] = useState(false)

  const LM =()=>{
    props.setMapZoom(2)
    props.setMapCenter([40.53459, -3.69723])
    props.setIsMapOpen(true)
  }

  return(

      <div className="navigationWrapper">

        <div className="navigationLogo" onClick={() => props.setShowMenu(false)}>
            <Link to="/" name="Link to Front Page">
              <img src = {logo} height="90px" width="182px" alt="Open Skateboarding Map Logo" />
            </Link>
        </div>

        <div className="navigationSearch">

          <SearchBar locationsList={props.locationsList} placeholder="Enter spot or park name" />

        </div>

        <div className="navigationLinks" onClick={() => props.setShowMenu(false)}>

          <button className='mapButton' id='mapModal' onClick={() => LM()}>MAP</button><br /><br />
          <Link style={{color:'black'}} to="/createlocation">Add Location</Link>

        </div>

        <div className="navigationUser">
          <div onClick={() => props.setShowMenu(false)}>

        {!auth.username ?
          <div>
          <button className='loginButton' id='login' onClick={() => props.setIsOpen(true)}> LOGIN</button> <br /><br />
          <Link style={{color:'black'}} to="/register">Register</Link>
          </div> :

            <Link to={`/profile/${auth.id}`}>
              <h2>{auth.username}</h2>
            </Link>

        }

        {auth.status &&
            <Logout />
        }
        </div>

        <div className='policyLinksIcon'onClick={()=>setShowLinks(!showLinks)}>
        <MoreHorizIcon />
        </div>

        {showLinks ?
          <div className='policyLinks'>
          <Link to={'/useragreement'} style={{color:'black'}} onClick={()=>props.setShowMenu(false)}>
            User Agreement
          </Link> <br />
          <Link to={'/privacypolicy'} style={{color:'black'}} onClick={()=>props.setShowMenu(false)}>
            Privacy Policy
          </Link> <br />
          <Link to={'/contentpolicy'} style={{color:'black'}} onClick={()=>props.setShowMenu(false)}>
            Content Policy
          </Link> <br />
          <Link to={'/contactus'} style={{color:'black'}} onClick={()=>props.setShowMenu(false)}>
            Contact Us
          </Link>

          </div>
        :
        ''
        }

        </div>

      </div>

  )
}

export default MobileNavigationItems
