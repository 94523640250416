import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from '../api/axios'
import useAuth from '../hooks/useAuth';
import PostsList from "./PostsList";
import '../css/Location.css'

function Location(props) {

  const { auth } = useAuth();

  const [nameError, setNameError] = useState("");
  const [featuresError, setFeaturesError] = useState("");
  const [latitudeError, setLatitudeError] = useState("");
  const [longitudeError, setLongitudeError] = useState("");
  const [linkError, setLinkError] = useState("");

  const validateEdit = () => {
    if(name===""||!typeof name ==='string'){
      name=locationObject.locationName
    }
    if(features===""||!typeof name ==='string'){
      features=locationObject.locationDesc
    }
    if(latitude===""|| isNaN(latitude)){
      latitude=locationObject.latitude
    }
    if(longitude===""|| isNaN(longitude)){
      longitude=locationObject.longitude
    }
    if(category===""||!typeof name ==='string'){
      category=locationObject.category
    }
    if(link===""||!typeof link ==='string'){
      link=locationObject.locationLink
    }
    return true
  }

  const handleLocationEdit = (e) =>{
    e.preventDefault()
    const isValid = validateEdit()
    if(isValid){
      editLocationTest()
    }
  }

  //////////

  let [name, setName] = useState("");
  let [features, setFeatures] = useState("");
  let [latitude, setLatitude] = useState("");
  let [longitude, setLongitude] = useState("");
  let [link, setLink] = useState("");
  let [category, setCategory] = useState("");
  let [size, setSize] = useState(20);

  const [locationsList, setLocationsList] = useState([])
  const [createDate, setCreateDate] = useState('')

  const editLocation = () =>{
    if(auth.id === 0 || auth.username === ""){
      alert('must sign in')
    } else {

      axios.put("/locations/editLocation", {
        headers: {
          accessToken: auth.accessToken
        },
        locationName: name,
        locationDesc: features,
        latitude: latitude,
        longitude: longitude,
        locationLink:link,
        category: category,
        size: size,
        id: locationObject.id
      }).then(() => {
        //used to console
      })
    }
  }

  const editLocationTest = () => {
    axios
      .put(
        "/locations/editLocation",
        {
          locationName: name,
          locationDesc: features,
          latitude: latitude,
          longitude: longitude,
          locationLink:link,
          category: category,
          size: size,
          id: locationObject.id
        },
        {
          headers: {
            accessToken: auth.accessToken
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error)

        } else {
          const locationToEdit = {
            locationName: name,
            locationDesc: features,
            latitude: latitude,
            longitude: longitude,
            locationLink:link,
            category: category,
            size: size,
            id: locationObject.id
          };
          alert(response.data)
          getLocations()
        }
      });
  };

  /*****/

  const [postTitle, setPostTitle] = useState('')
  const [postText, setPostText] = useState('')
  const [postLink, setPostLink] = useState('')

  const [postTitleError, setPostTitleError] = useState('')
  const [postTextError, setPostTextError] = useState('')
  const [postLinkError, setPostLinkError] = useState('')

  const validate = () => {
    let postTitleError =""
    let postTextError =""
    let postLinkError =""

    if(postTitle===""|| !typeof postTitle ==='string'){
      postTitleError = "add a title"
    }

    if(postTitleError) {
      setPostTitleError(postTitleError)
      return false
    }

    if(postText===""|| !typeof postText ==='string'){
      postTextError = "add text"
    }

    if(postTextError) {
      setPostTextError(postTextError)
      return false
    }

    if(!typeof postLink ==='string'){
      postLinkError = "strings only please"
    }

    if(postLinkError) {
      setPostLinkError(postLinkError)
      return false
    }

    return true
  }

///////

  const [newPost, setNewPost] = useState({});

  const addPost = () => {
    axios.post("/posts",{
      headers: {
        accessToken: auth.accessToken
      },
      postTitle: postTitle,
      postText: postText,
      postLink: postLink,
      LocationId: locationObject.id,
      UserId: auth.id,
      username: auth.username
    }).then((response)=>{
      //used to console
    })
  };

  const addPostTest = () => {
    axios
      .post(
        "/posts",
        {
          postTitle: postTitle,
          postText: postText,
          postLink: postLink,
          LocationId: locationObject.id,
          UserId: auth.id,
          username: auth.username
        },
        {
          headers: {
            accessToken: auth.accessToken
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert('must be logged in to add post')
        } else {
          const locationToAdd = {
            postTitle: postTitle,
            postText: postText,
            postLink: postLink,
            LocationId: locationObject.id,
            UserId: auth.id,
            username: auth.username
          };
          alert('post added')
          history('/')
        }
      });
  };

  const onSubmit = (e) => {
    e.preventDefault()
    const isValid = validate()
    if(isValid){
      addPostTest()
      e.target.reset()
      setPostTitleError('')
      setPostTextError('')
      setPostTitle('')
      setPostText('')
      setPostLink('')
    }
  };

  //////

  let { id } = useParams();
  const [locationObject, setLocationObject] = useState({});
  const [locationId, setLocationId] = useState('');

  const [displayName, setDisplayName] = useState('');

  let history = useNavigate();

  const getLocations = ()=>{
    axios.get(`/locations/byId/${id}`).then((response) => {
      try{
        setLocationObject(response.data.location);
        setCreateDate(response.data.createDate)
        setDisplayName(response.data.location.locationName)
      } catch(err){
        alert("location doesn't exist")
        history('/')
      }
    });
  }

  const LM =()=>{
    props.setMapZoom(18)
    props.setMapCenter([locationObject.latitude, locationObject.longitude])
    props.setIsMapOpen(true)
  }

  useEffect(() => {

    getLocations()

  }, []);

  return (
      <div>
        <div className="locationHeader">

          <div className="locationDesription">
            <h1 className="locationNameDisplay">{displayName}</h1>

            {locationObject.locationLink ?
              <iframe
                className="locationFrame"
                width="400" height="225"
                src={`https://www.youtube.com/embed/${locationObject.locationLink}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
                title="YouTube video player"
                allow="autoplay"
                frameBorder="0">
              </iframe> :

              '[ No media ]'
            }

            <div>{locationObject.category}</div>
            <div className='locationDescDisplay'>{locationObject.locationDesc =='coming soon...' ? 'desc. '+ locationObject.locationDesc: locationObject.locationDesc }</div>
            <div>lat: {locationObject.latitude}</div>
            <div>long: {locationObject.longitude}</div>
            <div>added by:{' '}
              <Link to={`/profile/${locationObject.UserId}`}>{locationObject.username} </Link>
              <br />on {createDate}
            </div><br />
            <div>
              <button className='mapButton' onClick={()=>LM()}>MAPVIEW</button>
            </div>

            <br /><br /><br />

          </div>

          <div className="editLocation">

            {auth.id ===locationObject.UserId ?

              <div className="">

              <h2>Edit Location</h2>

                <div className="information">
                  <form onSubmit={handleLocationEdit}>
                    <label>Name:</label>
                    <input
                      defaultValue={locationObject.locationName}
                      type="text"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                    <p style={{fontSize:16, color:'red'}}>{nameError}</p>

                    <label>Features:</label>
                    <input
                      defaultValue={locationObject.locationDesc}
                      type="text"
                      onChange={(event) => {
                        setFeatures(event.target.value);
                      }}
                    />
                    <p style={{fontSize:16, color:'red'}}>{featuresError}</p>

                    <label>Latitude:</label>
                    <input
                      defaultValue={locationObject.latitude}
                      type="text"
                      onChange={(event) => {
                        setLatitude(event.target.value);
                      }}
                    />
                    <p style={{fontSize:16, color:'red'}}>{latitudeError}</p>

                    <label>Longitude:</label>
                    <input
                      defaultValue={locationObject.longitude}
                      type="text"
                      onChange={(event) => {
                        setLongitude(event.target.value);
                      }}
                    />
                    <p style={{fontSize:16, color:'red'}}>{longitudeError}</p>

                    <label>Link:</label>
                    <input
                      defaultValue={locationObject.locationLink}
                      placeholder=" YouTube video string"
                      type="text"
                      onChange={(event) => {
                        setLink(event.target.value);
                      }}
                    />
                    <p style={{fontSize:16, color:'red'}}>{linkError}</p>

                    <label>Category:</label>
                    <select defaultValue={locationObject.category} onChange={(event) => {
                      setCategory(event.target.value)}}>
                      <option value="spot">Spot</option>
                      <option value="park">Park</option>
                    </select> <br /><br />
                    <input type="submit" className="loginButton" value="SEND EDIT" />
                  </form>
                </div>


                </div>
              : ''

            }

          </div>

          <div className="addPost">

          {auth.username ?

            <div className="createPost">

              <h2>Add Post</h2>

              <div className="information">
                <form onSubmit={onSubmit}>
                  <input
                    type="text"
                    placeholder=" title"
                    onChange={(event) => {
                      setPostTitle(event.target.value);
                    }}
                  />
                  <p style={{fontSize:16, color:'red'}}>{postTitleError}</p>

                  <input
                    type="text"
                    placeholder=" text"
                    onChange={(event) => {
                      setPostText(event.target.value);
                    }}
                  />
                  <p style={{fontSize:16, color:'red'}}>{postTextError}</p>

                  <input
                    type="text"
                    placeholder=" YouTube video string"
                    onChange={(event) => {
                      setPostLink(event.target.value);
                    }}
                  />
                  <br /><br />

                  <a href="#" className="postToolTip"><mark style={{fontSize:'.85em'}}>YouTube video string?</mark>
                    <span className="classic">
                      youtube.com/watch?v=<mark>dQw4w9WgXcQ</mark>
                    </span>
                  </a>
                  <br /><br />

                  <input type="submit" className="loginButton" value="POST" />
                </form>
              </div>
            </div>
            :
            <button className="addCommentButton" onClick={() => props.setIsOpen(true)}> ADD POST</button>
          }
          </div>

        </div>

      <PostsList id={id} />

      </div>
  );
}

export default Location;
