import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });
        
        setAuth({
          username: response.data.username,
          id: response.data.id,
          status: response.data.status,
          accessToken: response.data.accessToken
        })
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
