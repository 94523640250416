import React, {useState, useEffect, useContext} from 'react';
import axios from '../api/axios'
import { useNavigate } from "react-router-dom";
//import logo from '../assets/openSkateMapLogo.png'
import useAuth from '../hooks/useAuth';

function CreateLocation(props) {

  const { auth } = useAuth();

  let history = useNavigate();

  const [nameError, setNameError] = useState("");
  const [featuresError, setFeaturesError] = useState("");
  const [latitudeError, setLatitudeError] = useState("");
  const [longitudeError, setLongitudeError] = useState("");
  const [linkError, setLinkError] = useState("");

  const validate = () => {
    let nameError =""
    let featuresError =""
    let latitudeError =""
    let longitudeError =""
    let linkError =""

    /*Coordinates validation*/
    let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');

    if(!auth.id || !auth.username){
      alert('must sign in to create location')
      return false
    }

    if(name===""|| !typeof name ==='string'){
      nameError = "add a name"
    }

    if(nameError) {
      setNameError(nameError)
      return false
    }

    if(features===""|| !typeof features ==='string'){
      featuresError = "add features"
    }

    if(featuresError) {
      setFeaturesError(featuresError)
      return false
    }

    //if(latitude==="" || pattern.test(latitude) ===false){
    if(latitude==="" || isNaN(latitude)){
      latitudeError = "must have a valid latitude number"
    }

    if(latitudeError) {
      setLatitudeError(latitudeError)
      return false
    }

    //if(longitude==="" || pattern.test(longitude) ===false){
    if(longitude==="" || isNaN(longitude)){
      longitudeError = "must have a longitude number"
    }

    if(longitudeError) {
      setLongitudeError(longitudeError)
      return false
    }

    if(!typeof link ==='string') {
      linkError = "link must be a string"
    }

    if(linkError) {
      setLinkError(linkError)
      return false
    }

    return true
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    const isValid = validate()
    if(isValid){
      //addLocation()
      addLocationTest()
      e.target.reset();
      setNameError('')
      setFeaturesError('')
      setLatitudeError('')
      setLongitudeError('')
      setName('')
      setFeatures('')
      setLatitude('')
      setLongitude('')
      setLink('')
    }
  }

  //////////

  const [name, setName] = useState("");
  const [features, setFeatures] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [link, setLink] = useState("");
  const [category, setCategory] = useState("spot");
  const [size, setSize] = useState(20);

  const [locationsList, setLocationsList] = useState([])

  const addLocation = () =>{
    if(auth.id === 0 || auth.username === ""){
      alert('must sign in')
    } else{
      axios.post("/locations", {
        headers: { accessToken: localStorage.getItem("accessToken") },
        locationName: name,
        locationDesc: features,
        latitude: latitude,
        longitude: longitude,
        category: category,
        size: size,
        username: auth.username,
        UserId: auth.id
      }).then(() => {
        history('/')
      })
    }
  }

  const addLocationTest = () => {
    axios
      .post(
        "/locations",
        {
          locationName: name,
          locationDesc: features,
          latitude: latitude,
          longitude: longitude,
          locationLink: link,
          category: category,
          size: size,
          username: auth.username,
          UserId: auth.id
        },
        {
          headers: {
            accessToken: auth.accessToken
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error)
        } else {
          const locationToAdd = {
            locationName: name,
            locationDesc: features,
            latitude: latitude,
            longitude: longitude,
            locationLink: link,
            category: category,
            size: size,
            username: auth.username,
            UserId: auth.id
          };
          history('/')
          alert('location added')
        }
      });
  };

  useEffect(() => {
    if (!auth.accessToken) {
      props.setIsOpen(true)
    }

  }, []);

  /*const routeTest = () => {
    axios
      .get(
        "/likes",
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error)
        } else {
          alert(response.data)
        }
      });
  };*/


  return (

    <div className="add">

      <h1>Create Location</h1>

      <div className="information">
        <form onSubmit={handleSubmit}>
          <input
            id='nameInput'
            type="text"
            placeholder=" name"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <p style={{fontSize:16, color:'red'}}>{nameError}</p>

          <input
            id='featuresInput'
            type="text"
            placeholder=" features"
            onChange={(event) => {
              setFeatures(event.target.value);
            }}
          />
          <p style={{fontSize:16, color:'red'}}>{featuresError}</p>

          <input
            id='latitudeInput'
            type="text"
            placeholder=" latitude"
            onChange={(event) => {
              setLatitude(event.target.value);
            }}
          />
          <p style={{fontSize:16, color:'red'}}>{latitudeError}</p>

          <input
            id='longitudeInput'
            type="text"
            placeholder=" longitude"
            onChange={(event) => {
              setLongitude(event.target.value);
            }}
          />
          <p style={{fontSize:16, color:'red'}}>{longitudeError}</p>

          <input
            id='ytInput'
            type="text"
            placeholder=" YouTube video string"
            onChange={(event) => {
              setLink(event.target.value);
            }}
          />
          <br />

          <br />

            <a href="#" className="ytToolTip"><mark style={{fontSize:'.85em'}}>YouTube video string?</mark>
              <span className="classic">
                youtube.com/watch?v=<mark>dQw4w9WgXcQ</mark>
              </span>
            </a>

          <p style={{fontSize:16, color:'red'}}>{linkError}</p>

          <label>Category:{' '}</label>
          <select id="categoryInput" onChange={(event) => {
            setCategory(event.target.value)}}>
            <option value="spot">Spot</option>
            <option value="park">Park</option>
            {/*<option value="history">History</option>*/}
          </select> <br /><br />
          <input id='addLoc' type="submit" className="registerButton" value="ADD LOCATION" />
        </form>
      </div>

    </div>
  );
}

export default CreateLocation;
