import React, { useState } from 'react';
import useAuth from '../hooks/useAuth'
import { Link } from 'react-router-dom'
import logo from '../assets/openSkateboardingMapLogo.png'
import '../css/MobileNavigation.css'
import Logout from './Logout'
import SearchBar from './SearchBar'
import Navigation from './Navigation'
import MobileNavigationItems from './MobileNavigationItems'
import MenuIcon from '@material-ui/icons/Menu';

const MobileNavigation = (props) => {

  const [showMenu, setShowMenu] = useState(false)

  return(

    <div className="mobileNavigationWrapper">

      <div onClick={() => setShowMenu(false)}>
        <Link to="/" name="Link to Front Page">
          <img src = {logo} height="90px" width="182px" alt="Open Skateboarding Map Logo" />
        </Link>
      </div>

      <div className="burgerNav" onClick={() => setShowMenu(!showMenu)}>
        <MenuIcon />
      </div>


        {showMenu ? <MobileNavigationItems
                      setMapZoom={props.setMapZoom}
                      setMapCenter={props.setMapCenter}
                      setIsMapOpen={props.setIsMapOpen}
                      setIsOpen={props.setIsOpen}
                      locationsList={props.locationsList}
                      setShowMenu={setShowMenu}
                    /> : ''

        }
        {/*tried wrapping above in setShowMenu but search would become inaccesible*/}

    </div>

  )

}

export default MobileNavigation;
