import React, { useState, useEffect } from "react";
import axios from '../api/axios'
import { useNavigate, useParams, Link } from "react-router-dom";

function ResetPassword(props) {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [validUrl, setValidUrl] = useState(false);
	const param = useParams();

  const verifyEmailUrl = async () => {
    try {
      const url = `/auth/checktoken/${param.verifyToken}`;
      const { data } = await axios.get(url);
      setValidUrl(true);
    } catch (error) {
      //smile
    }
  };

  let history = useNavigate();

  const ResetPassword = () => {
    if(newPassword.length <8){
      alert('password must be at least 8 characters')
      return false
    }
    else if(newPassword != newPasswordConfirm){
      alert('your new password & confirmation must match')
      return false
    }
    axios
      .put(
        `/auth/resetpassword/${param.verifyToken}`,
        {
          newPassword: newPassword,
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
          history('/')
        } else {
          alert('password reset')
          history('/')
          props.setIsOpen(true)
        }
      });
  };

  useEffect(() => {

		verifyEmailUrl();

	}, []);

  return (
    <div className="password">
      {validUrl ? (
        <div className =''>
          <h1>Reset Your Password</h1>
          <input
            type="password"
            placeholder="New Password..."
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          /> <br /><br />
          <input
            type="password"
            placeholder="Confirm New Password..."
            onChange={(event) => {
              setNewPasswordConfirm(event.target.value);
            }}
          /> <br /><br />
          <button onClick={ResetPassword} className="loginButton"> SAVE CHANGES</button>
        </div>

      ): (
        <div>
          <h1>404: Page Not Found</h1>
          <h3>
            Go to the <Link to="/"> Home Page</Link>
          </h3>
        </div>
      )}

    </div>
  );
}

export default ResetPassword;
