import React from 'react';
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from '../api/axios'

const VerifyEmail = (props) => {
	const [validUrl, setValidUrl] = useState(false);
	const param = useParams();

  const verifyEmailUrl = async () => {
    try {
      const url = `/auth/${param.name}/verify/${param.verifyToken}`;
      const { data } = await axios.get(url);
      setValidUrl(true);
    } catch (error) {
    	//setValidUrl(false);
    }
  };

	useEffect(() => {

		verifyEmailUrl();

	}, []);

	return (
		<div style={{marginTop: '150px'}}>
			{validUrl ? (
				<div className=''>
					<img src='' alt='' className='' />
					<h1>Email successfully verified!</h1>
            <h3 onClick ={()=>props.setIsOpen(true)}>
              <Link to="/"> Login</Link>
            </h3>
				</div>
			) : (
        <div>
          <h1>404: Page Not Found</h1>
          <h3>
            Go to the <Link to="/"> Home Page</Link>
          </h3>
        </div>
			)}
		</div>
	);
};

export default VerifyEmail;
