import React, { useState } from "react";
import axios from '../api/axios'
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  let history = useNavigate();

  const checkEmail = (e) => {
    e.preventDefault()
    axios
      .put(
        "/auth/checkmail",

          {email:email,}

      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
          history('/')
        } else {
          alert(response.data)
          history('/')
        }
      });

  };

  return (
    <div className="password">
      <h2>Forgot your password or username?</h2>
      <h3>Send a registered email to receive a reset link.</h3>

      <form onSubmit={checkEmail}>

      <input
        type="email"
        placeholder="email"
        onChange={(event) => {
          setEmail(event.target.value);
        }}
      /> <br /><br />

      <input className="registerButton" type="submit" value="SEND" />
      </form>
    </div>
  );
}

export default ForgotPassword;
