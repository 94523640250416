import React from 'react';
import { useNavigate } from "react-router-dom";
import '../css/Trending.css'

const Trending = (props)=>{

  const history = useNavigate();

  return(

    <div className='trendingPosts'>
      <h2>Trending Posts</h2>

      {props.listOfPosts.map((value,key)=>{
          return(

            <div key ={key} className='trendingPostItem'
            onClick={() => {
                  history(`/post/${value.id}`);
                }}
            >
            <ul>
            <li><h3 style={{display:'inline'}}>{value.postTitle}</h3></li>
            <li>posted by:{' '}{value.username}</li>
            <li>{value.Likes.length} likes</li>
            </ul>


            {value.postLink ?
              <iframe
                className="trendingFrame"
                width="550" height="309"
                src={`https://www.youtube.com/embed/${value.postLink}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
                title="YouTube video player"
                allow="autoplay"
                frameBorder="0">

              </iframe> :
              '[ No media ]'
            } <br />


            <br /><br />
            </div>

          )
        })
      }

    </div>

  )

}

export default Trending
