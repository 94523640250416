import React from 'react';
import '../css/Policy.css'
import { Link } from 'react-router-dom';

const PrivacyPolicy = ()=>{
  return(
    <div className="policyHolder">
      <h1 id="privacyPolicyTop">Open Skateboarding Map(OSM) <br />Privacy Policy</h1>

        <p>
          Effective July 4, 2022. Last Revised July 4, 2022 <br />
          We want you to understand how and why Open Skateboarding Map (“OSM,” “we” or “us”) collects, uses, and shares information about you when you use our sites, mobile apps, widgets, and other online products and services (collectively, the “Services”) or when you otherwise interact with us or receive a communication from us. This Privacy Policy applies to all of our Services
        </p>

      <h2>What We Collect (and How it is Used and Shared)</h2>

      <h3>Information You Provide to Us</h3>
        <p>
          We collect information you provide to us directly when you use the Services. This includes:
        </p>

        <h4>Account information</h4>
          <p>
            If you create a OSM account, we may require you to provide a username and password. Your username is public, and it doesn’t have to be related to your real name. You may also provide other account information, like an email address, bio, or profile picture. We also store your user account preferences and settings.
          </p>

        <h4>Content you submit</h4>
          <p>
            We collect the content you submit to the Services. This includes your posts and comments. Your content may include text, links, images, gifs, and videos.
          </p>

        <h4>Actions you take</h4>
          <p>
            We collect information about the actions you take when using the Services. This includes your interactions with content, like voting, saving, hiding, and reporting. It also includes your interactions with other users, such as following, friending, and blocking.
          </p>

        <h4>Other information</h4>
          <p>
            You may choose to provide other information directly to us. For example, we may collect information when you fill out a form, participate in OSM-sponsored activities or promotions, apply for a job, request customer support, or otherwise communicate with us.
          </p>

      <h3>Information We Collect Automatically</h3>
        <p>
          When you access or use our Services, we may also automatically collect information about you. This includes:
        </p>

        <h4>Log and usage data</h4>
          <p>
            We may log information when you access and use the Services. This may include your IP address, user-agent string, browser type, operating system, referral URLs, device information (e.g., device IDs), device settings, mobile carrier name, pages visited, links clicked, the requested URL, and search terms. Except for the IP address used to create your account, OSM will delete any IP addresses collected after 100 days.
          </p>

        <h4>Information collected from cookies and similar technologies</h4>
          <p>
            We may receive information from cookies, which are pieces of data your browser stores and sends back to us when making requests, and similar technologies. We use this information to improve your experience, understand user activity, personalize content and advertisements, and improve the quality of our Services. For example, we store and retrieve information about your preferred language and other settings. For more information on how you can disable cookies, please see <a href="#yourChoices">Your Choices</a> below.
          </p>

        <h4>Location information</h4>
          <p>
            We may receive and process information about your location. For example, with your consent, we may collect information about the specific location of your mobile device (for example, by using GPS or Bluetooth). We may also receive location information from you when you choose to share such information on our Services, including by associating your content with a location, or we may derive your approximate location from other information about you, including your IP address.
          </p>

      <h3>Information Collected from Other Sources</h3>
        <p>
          We may receive information about you from other sources, including from other users and third parties, and combine that information with the other information we have about you. For example, we may receive demographic or interest information about you from third parties, including advertisers (such as the fact that an advertiser is interested in showing you an ad), and combine it with our own data using a common account identifier such as a hash of an email address or a mobile-device ID. You can control how we use this information to personalize the Services for you as described in <a href="#yourChoices">Your Choices - Controlling Advertising and Analytics</a> below.
        </p>

      <h3>Information Collected by Third Parties</h3>

        <h4>Embedded content</h4>
          <p>
            OSM displays some linked content in-line on the OSM services via “embeds.” For example, OSM posts that link to YouTube or Twitter may load the linked video or tweet within OSM directly from those services to your device so you don’t have to leave OSM to see it. In general, OSM does not control how third-party services collect data when they serve you their content directly via these embeds. As a result, embedded content is not covered by this privacy policy but by the policies of the service from which the content is embedded.
          </p>

        <h4>Audience measurement</h4>
          <p>
            We partner with audience measurement companies (including Quantcast and Nielsen) to learn demographic information about the population that uses OSM. To provide this demographic information, these companies collect cookie information to recognize your device.
          </p>

      <h3>We use information about you to:</h3>
        <ul>
          <li>
            Provide, maintain, and improve the Services;
          </li>
          <li>
            Research and develop new services;
          </li>
          <li>
            Help protect the safety of OSM and our users, which includes blocking suspected spammers, addressing abuse, and enforcing the <Link to='/useragreement'>OSM User Agreement</Link> and our other policies;
          </li>
          <li>
            Send you technical notices, updates, security alerts, invoices, and other support and administrative messages;
          </li>
          <li>
            Provide customer service;
          </li>
          <li>
            Communicate with you about products, services, offers, promotions, and events, and provide other news and information we think will be of interest to you (for information about how to opt out of these communications, see <a href="#yourChoices">Your Choices</a> below);
          </li>
          <li>
            Monitor and analyze trends, usage, and activities in connection with our Services;
          </li>
          <li>
            Measure the effectiveness of ads shown on our Services; and
          </li>
          <li>
            Personalize the Services, and provide and optimize advertisements, content, and features that match user profiles or interests.
          </li>
        </ul>

        <p>
          Much of the information on the Services is public and accessible to everyone, even without an account. By using the Services, you are directing us to share this information publicly and freely.
        </p>

        <p>
          When you submit content (including a post or comment{/*, chat message, or RPAN broadcast*/}) to a public part of the Services, any visitors to and users of our Services will be able to see that content, the username associated with the content, and the date and time you originally submitted the content. OSM allows other sites to embed public OSM content via our embed tools. OSM also allows third parties to access public OSM content via the OSM API and other similar technologies. Although some parts of the Services may be private or quarantined, they may become public {/*(e.g., at the moderator’s option in the case of private communities)*/} and you should take that into consideration before posting to the Services.
        </p>

        <p>
          Your OSM account has a profile page that is public. Your profile contains information about your activities on the Services, such as your username, prior posts and comments{/*, karma, awards received, trophies, moderator status, OSM Premium status*/}, and how long you have been a member of the Services. You can also choose for your profile to include the content you upvote and downvote.
        </p>

        <p>
          We do not sell your personal information. However, in addition to the personal information that is displayed publicly as described above, we may share personal information in the following ways:
        </p>

          <ul>
            <li>
              With your consent. We may share information about you with your consent or at your direction.
            </li>

            <li>
              With our service providers. We may share information with vendors, consultants, and other service providers who need access to such information to carry out work for us. Their use of personal data will be subject to appropriate confidentiality and security measures. A few examples: (i) payment processors who process transactions on our behalf, (ii) cloud providers who host our data and our services, (iii) third-party ads measurement providers who help us and advertisers measure the performance of ads shown on our Services.
            </li>

            <li>
              To comply with the law. We may share information in response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, legal process, or governmental request, including, but not limited to, meeting national security or law enforcement requirements. To the extent the law allows it, we will attempt to provide you with prior notice before disclosing your information in response to such a request.
            </li>

            <li>
              In an emergency. We may share information if we believe it's necessary to prevent imminent and serious bodily harm to a person.
            </li>

            <li>
              To enforce our policies and rights. We may share information if we believe your actions are inconsistent with our <Link to='/useragreement'>User Agreement</Link>, rules, or other OSM policies, or to protect the rights, property, and safety of ourselves and others.
            </li>

            <li>
              With our affiliates. We may share information between and among OSM, and any of our parents, affiliates, subsidiaries, and other companies under common control and ownership.
            </li>

            <li>
              Aggregated or de-identified information. We may share information about you that has been aggregated or anonymized such that it cannot reasonably be used to identify you. For example, we may show the total number of times a post has been upvoted without identifying who the visitors were, or we may tell an advertiser how many people saw their ad.
            </li>
          </ul>

{/*alignment*/}

      <h2>How We Protect Your Information</h2>

        <p>
          We take measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction. For example, we use HTTPS while information is being transmitted. We also enforce technical and administrative access controls to limit which of our employees have access to nonpublic personal information.
        </p>

        <p>
          We store the information we collect for as long as it is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes or as required by law.
        </p>

      <h2 id="yourChoices">Your Choices</h2>

        <p>
          You have choices about how to protect and limit the collection, use, and sharing of information about you when you use the Services. Some of these choices are available to everyone who uses OSM, while others only apply if you have a OSM account.
        </p>

        <h4>Accessing and Changing Your Information</h4>

          <p>
            You can access and change certain information through the Services. Refer to your logged in account profile page for options. You can also request a copy of the personal information OSM maintains about you <Link to="/contactus">here</Link>.
          </p>

        <h4>Deleting Your Account</h4>
          <p>
            You may delete your account information at any time from the user profile page. You can also submit a request to delete the personal information OSM maintains about you by following the process described in the <a href="#yourRights">Your Rights - Data Subject and Consumer Information Requests</a> section below. When you delete your account, your profile is no longer visible to other users and disassociated from content you posted under that account. Please note, however, that the posts, comments, and messages you submitted prior to deleting your account will still be visible to others unless you first delete the specific content. We may also retain certain information about you as required by law or for legitimate business purposes after you delete your account.
          </p>

        <h4>Controlling the Use of Cookies</h4>
          <p>
            Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject first- and third-party cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services.
          </p>

        <h4>Controlling Advertising and Analytics</h4>
          <p>
            Some analytics providers we partner with may provide specific opt-out mechanisms and we may provide, as needed and as available, additional tools and third-party services that allow you to better understand cookies and how you can opt out. For example, you may manage the use and collection of certain information by <a href='https://policies.google.com/technologies/partner-sites' target="_blank" rel="noopener noreferrer">Google Analytics</a> via the <a href='https://tools.google.com/dlpage/gaoptout' target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a>. You can opt out of the Audience Measurement services provided by <a href='https://global.nielsen.com/legal/privacy-principles/digital-measurement-privacy-statement/#choices' target="_blank" rel="noopener noreferrer">Nielsen</a> and <a href='https://www.quantcast.com/opt-out/' target="_blank" rel="noopener noreferrer">Quantcast</a>.
          </p>

          <p>
             You can use device-level settings to control personalized advertisements on Android (“Reset advertising ID” and “Opt out of Ads Personalization”) and iOS (“Limit Ad Tracking”) devices. You may also generally opt out of receiving personalized advertisements from certain third-party advertisers and ad networks. To learn more about these advertisements or to opt out, please visit the sites of the <a href='https://optout.aboutads.info/?c=2&lang=EN#!' target="_blank" rel="noopener noreferrer">Digital Advertising Alliance</a> and the <a href='https://optout.networkadvertising.org/?c=1#!' target="_blank" rel="noopener noreferrer">Network Advertising Initiative</a>, or if you are a user in the European Economic Area, <a href='https://www.youronlinechoices.eu/' target="_blank" rel="noopener noreferrer">Your Online Choices</a>.
          </p>

        <h4>Do Not Track</h4>
          <p>
            Most modern web browsers give you the option to send a Do Not Track signal to the sites you visit, indicating that you do not wish to be tracked. However, there is no accepted standard for how a site should respond to this signal, and we do not take any action in response to this signal. Instead, in addition to publicly available third-party tools, we offer you the choices described in this policy to manage the collection and use of information about you.
          </p>

        <h4>Controlling Promotional Communications</h4>
          <p>
            You may opt out of receiving some or all categories of promotional communications from us by following the instructions in those communications or by updating your email option on your account profile page. If you opt out of promotional communications, we may still send you non-promotional communications, such as information about your account or your use of the Services.
          </p>

        <h4>Controlling Mobile Notifications</h4>
          <p>
            With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.
          </p>

        <h4>Controlling Location Information</h4>
          <p>
            You can control how we use location information inferred from your IP address for content recommendation purposes via the Location setting on your account profile page. If you initially consent to our collection of more precise location information from your device, you can subsequently stop the collection of this information at any time by changing the preferences on your mobile device.
          </p>

      <h2 id="yourRights">Your Rights</h2>

        <h3>Data Subject and Consumer Information Requests</h3>
          <p>
            Requests for a copy of the information OSM has about your account—including EU General Data Protection Regulation (“GDPR”) data subject access requests and California Consumer Privacy Act (“CCPA”) consumer information requests—can be submitted <Link to="/contactus">here</Link>.
          </p>

          <p>
            All other data subject and consumer requests under data protection laws should be sent via email to <a href="mailto:inquires@openskateboardingmap.com" target="_blank" rel="noopener noreferrer">inquires@openskateboardingmap.com</a> from the email address that you have verified with your OSM account. Other inquiries related to your privacy rights can be submitted <Link to="/contactus">here</Link>.
          </p>

          <p>
            Before we process a request from you about your personal information, we need to verify the request via your access to your OSM account or to a verified email address associated with your OSM account. You may also designate an authorized agent to exercise these rights on your behalf. OSM does not discriminate against users for exercising their rights under data protection laws to make requests regarding their personal information.
          </p>

        <h3>International Data Transfers</h3>
          <p>
            Open Skateboarding Map is based in the United States and we process and store information on servers located in the United States. We may store information on servers and equipment in other countries depending on a variety of factors, including the locations of our users and service providers. By accessing or using the Services or otherwise providing information to us, you consent to the processing, transfer, and storage of information in and to the U.S. and other countries, where you may not have the same rights as you do under local law.
          </p>

          <p>
            When we transfer the personal data of users in the EEA, UK and/or Switzerland, to OSM, Inc., we rely on the Standard Contractual Clauses approved by the European Commission for such transfers or other transfer mechanisms deemed ‘adequate’ under applicable laws. In addition, OSM adheres to the EU-U.S. and Swiss-U.S. Privacy Shield Program (“Privacy Shield”) and complies with its framework and principles.
          </p>

          <p>
            Please direct any inquiries or complaints regarding our compliance with the Privacy Shield principles to the point of contact listed in the <a href="#contactUS">Contact Us</a> section below. If we do not resolve your complaint, you may submit your complaint free of charge to <a href='https://www.jamsadr.com/eu-us-privacy-shield' target="_blank" rel="noopener noreferrer">JAMS</a>. Under certain conditions specified by the Privacy Shield principles, you may also be able to invoke binding arbitration to resolve your complaint. We are subject to the investigatory and enforcement powers of the Federal Trade Commission. In certain circumstances, we may be liable for the transfer of personal data from the EU, Switzerland, or the UK to a third party outside those countries.
          </p>

          <p>
            For more information about the Privacy Shield principles and to view our certification, please visit the <a href='https://www.privacyshield.gov/welcome' target="_blank" rel="noopener noreferrer">U.S. Department of Commerce’s Privacy Shield site</a>.
          </p>

        <h3>Additional Information for EEA Users</h3>
          <p>
            Users in the European Economic Area have the right to request access to, rectification of, or erasure of their personal data; to data portability in certain circumstances; to request restriction of processing; to object to processing; and to withdraw consent for processing where they have previously provided consent. These rights can be exercised using the information provided under <a href="#yourChoices">Your Choices</a> above or as described in the<a href="#yourRights"> Your Rights - Data Subject and Consumer Information Requests</a> section above. EEA users also have the right to lodge a complaint with their local supervisory authority.
          </p>

          <p>
            As required by applicable law, we collect and process information about individuals in the EEA only where we have a legal basis for doing so. Our legal bases depend on the Services you use and how you use them. We process your information on the following legal bases:
          </p>

          <ul>
            <li>
              You have consented for us to do so for a specific purpose;
            </li>

            <li>
              We need to process the information to provide you the Services, including to operate the Services, provide customer support and personalized features and to protect the safety and security of the Services;
            </li>

            <li>
              It satisfies a legitimate interest (which is not overridden by your data protection interests), such as preventing fraud, ensuring network and information security, enforcing our rules and policies, protecting our legal rights and interests, research and development, personalizing the Services, and marketing and promoting the Services; or
            </li>

            <li>
              We need to process your information to comply with our legal obligations.
            </li>
          </ul>

        <h3>Additional Information for California Users</h3>
          <p>
            The California Consumer Privacy Act (CCPA) requires us to provide California residents with some additional information about the categories of personal information we collect and share, where we get that personal information, and how and why we use it.
          </p>

          <p>
            In the last 12 months, we collected the following categories of personal information from California residents, depending on the Services used:
          </p>

          <ul>
            <li>
              Identifiers, like your OSM username, email address, IP address, and cookie information.
            </li>

            <li>
              Commercial information, including information about transactions you undertake with us.
            </li>

            <li>
              Internet or other electronic network activity information, such as information about your activity on our Services and limited information about your activity on the services of advertisers who use our advertising technology.
            </li>

            <li>
              Geolocation information based on your IP address, or more specific location information if you authorize your device to provide it to us.
            </li>

            <li>
              Audiovisual information in pictures, audio, or video content submitted to OSM.
            </li>

            <li>
              Professional or employment-related information or demographic information, but only if you explicitly provide it to us, such as by applying for a job or filling out a survey.
            </li>

            <li>
              Inferences we make based on other collected data, for purposes such as recommending content, advertising, and analytics.
            </li>
          </ul>

          <p>
            You can find more information about (a) what we collect and sources of that information, (b) the business and commercial purposes for collecting that information, and (c) the categories of third parties with whom we share that information in the <a href="#privacyPolicyTop">What We Collect (and How it is Used and Shared)</a> section above.
          </p>

          <p>
            If you are a California resident, you have additional rights under the CCPA, including the right to request access to or deletion of your personal information, and information about our data practices, as well as the right not to be discriminated against for exercising your privacy rights. These rights can be exercised as described in the Data Subject and Consumer Information Requests section above.
          </p>

        <h3>Children</h3>
          <p>
            Children under the age of 13 are not allowed to create an account or otherwise use the Services. Additionally, if you are in the EEA, you must be over the age required by the laws of your country to create an account or otherwise use the Services, or we need to have obtained verifiable consent from your parent or legal guardian.
          </p>

        <h3>Changes to This Policy</h3>
          <p>
            We may change this Privacy Policy from time to time. If we do, we will let you know by revising the date at the top of the policy. If the changes, in our sole discretion, are material, we may also notify you by sending an email to the address associated with your account (if you have chosen to provide an email address) or by otherwise providing notice through our Services. We encourage you to review the Privacy Policy whenever you access or use our Services or otherwise interact with us to stay informed about our information practices and the ways you can help protect your privacy. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.
          </p>

      <h2 id="contactUS">Contact Us</h2>
        <p>
          To send a GDPR data subject request or CCPA consumer request, or if you have other inquiries about your privacy rights, follow the steps in the <a href="#yourRights">Your Rights - Data Subject and Consumer Information Requests</a> section above.
        </p>

        <p>
          If you have other questions about this Privacy Policy, please contact us <Link to="/contactus">here</Link>
        </p>

        <br />
        <p onClick={()=>window.scrollTo(0, 0)}>
          BACK TO TOP
        </p>

    </div>
  )

}

export default PrivacyPolicy
