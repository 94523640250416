import React, { useState } from 'react';
import axios from '../api/axios'
import { useNavigate } from "react-router-dom";

function Registration(props) {

  const history = useNavigate()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [email, setEmail] = useState('')

  const validate = ()=>{
    let pattern = new RegExp(/\s/g);
    const nameTest = pattern.test(username) // returns true for spaces
    if(nameTest){
      alert('please avoid spaces in username')
      return false
    } else
    if(password.length <8){
      alert('password must be at least 8 characters')
      return false
    }
    if(password != passwordConfirm){
      alert('password & confirmation must match')
      return false
    } else{
      return true
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const isValid = validate()
    if(isValid){
      registerUser()
    }
  };

  const registerUser =()=>{
    axios.post("/auth", {
      name: username,
      pwd: password,
      mail: email
    }).then((response) =>{
      if(response.data.error){
        alert(response.data.error)
      } else{
        alert(response.data)
        history("/");
        props.setIsOpen(true)
      }
    })
  }

  return (

    <div className="register">

      <h1>Register</h1>

      <div className="information">
        <form onSubmit={onSubmit}>
          <input
            type="text"
            placeholder='username'
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
          <br /><br />

          <input
            type="email"
            placeholder='email'
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <br /><br />

          <input
            type="password"
            placeholder='password'
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <br /><br />

          <input
            type="password"
            placeholder='confirm password'
            onChange={(event) => {
              setPasswordConfirm(event.target.value);
            }}
          />
          <br /><br />

          <input className="registerButton" type="submit" value="REGISTER" />
        </form>
      </div>

    </div>
  );
}

export default Registration;
