import React from 'react'
import { useNavigate } from "react-router-dom";
import '../css/Headlines.css'

const Headlines = (props) => {

  const history = useNavigate();

  const laterD = 'zR5OvACzXjg'

  return (

    <div className="headlinesWrapper">

      <div className="newestPost">
        <h2>Freshest Post</h2>

        {props.newPosts.map((value,key)=>{
            return(

              <div key ={key} className='newestPostItem'
              onClick={() => {
                    history(`/post/${value.id}`);
                  }}
              >

              <div style={{fontSize:'1.15em', fontWeight:'bold'}}>{value.postTitle}</div>
              <div>posted by:{' '}{value.username}</div>
              <div>{value.Likes.length} likes</div>
              <div>


              {value.postLink ?
                <iframe
                  className="headlineFrame"
                  width="300" height="169"
                  src={`https://www.youtube.com/embed/${value.postLink}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
                  title="YouTube video player"
                  allow="autoplay"
                  frameBorder="0">

                </iframe> :
                '[ No media ]'
              } <br />

              </div>


              </div>

            )
          })
        }

      </div>
      <div className="newestLocation">
        <h2>Latest Location</h2>

        {props.newLocations.map((value,key)=>{
            return(

              <div key ={key} className='newestLocationItem'
              onClick={() => {
                    history(`/location/${value.id}`);
                  }}
              >

              <div style={{fontSize:'1.15em', fontWeight:'bold'}}>{value.locationName}</div>
              <div>{value.category}</div>
              <div>added by:{' '}
                {value.username}{' '}
              </div>
              <div>

              {value.locationLink ?
                <iframe
                  className="headlineFrame"
                  width="300" height="169"
                  src={`https://www.youtube.com/embed/${value.locationLink}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
                  title="YouTube video player"
                  allow="autoplay"
                  frameBorder="0">

                </iframe> :
                '[ No media ]'
              } <br />

              </div>

              </div>

            )
          })
        }

      </div>
      <div className="prolificPoster">
        <h2>Los Mas</h2>

        <h3>Most Posts</h3>

        {props.proUsers.map((value,key)=>{
            return(
              <h5 key={key} style={{cursor:'pointer'}}
                onClick={() => {
                      history(`/profile/${value.id}`);
                    }}
              >
                {value.username}{' '}({value.Posts.length} posts)
              </h5>
            )}
          )
        }

        <h3>Most Locations</h3>

        {props.locosList.map((value,key)=>{
            return(
              <h5 key={key} style={{cursor:'pointer'}}
                onClick={() => {
                      history(`/profile/${value.id}`);
                    }}
              >
                {value.username}{' '}({value.Locations.length} locations)
              </h5>
            )}
          )
        }

      </div>
      <div className="historyHighlight">
        <h2>Later'd</h2>

        <div className="historyHighlightItem" style={{cursor:'auto'}}>
        <div style={{fontSize:'1.15em', fontWeight:'bold'}}>Carlsbad Gap</div>
        <div>spot</div>
        <div>Carlsbad, CA</div>
        <div>
        <iframe
          className="headlineFrame"

          src={`https://www.youtube.com/embed/${laterD}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
          title="YouTube video player"
          allow="autoplay"
          frameBorder="0">

        </iframe>
        </div>
        </div>

      </div>

    </div>
  );
}

export default Headlines;
