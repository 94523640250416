import React, { useEffect, useState, useContext } from "react";
import axios from '../api/axios'
import { Link, useNavigate } from "react-router-dom";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUp";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useAuth from '../hooks/useAuth';
import '../css/PostList.css'

const PostsList = (props) =>{

  const [listOfPosts, setListOfPosts] = useState([]);
  const [likedPosts, setLikedPosts] = useState([]);
  const { auth } = useAuth();
  const history = useNavigate();

  const getPosts = () => {
    const userId = auth.id
      if (!auth.accessToken) {
        axios.get(`/posts/${props.id}`).then((response) => {
          setListOfPosts(response.data.listOfPosts);
          setLikedPosts(
            response.data.likedPosts.map((like) => {
              return like.PostId;
            })
          );
        })

      } else {
        axios.get(`/posts/valid/${props.id}`, {
          headers: { accessToken: auth.accessToken }
        })
        .then((response) => {
          setListOfPosts(response.data.listOfPosts);
          setLikedPosts(
            response.data.likedPosts.map((like) => {
              return like.PostId;
            })
          );
        })
      }
  };

  useEffect(() => {
    getPosts()
  }, [auth]);

  const likeAPost = (postId) => {
    axios
      .post(
        "/likes",
        { PostId: postId },
        { headers: { accessToken: auth.accessToken } }
      )
      .then((response) => {
        setListOfPosts(
          listOfPosts.map((post) => {
            if (post.id === postId) {
              if (response.data.liked) {
                return { ...post, Likes: [...post.Likes, 0] };
              } else {
                const likesArray = post.Likes;
                likesArray.pop();
                return { ...post, Likes: likesArray };
              }
            } else {
              return post;
            }
          })
        );

        if (likedPosts.includes(postId)) {
          setLikedPosts(
            likedPosts.filter((id) => {
              return id != postId;
            })
          );
        } else {
          setLikedPosts([...likedPosts, postId]);
        }
      });
  };

  return (

    <div>

    {listOfPosts.map((value,key)=>{
      return(

        <div key={key} className="postListWrapper">

          <div className="postListOne">
            <div>
             {value.postTitle}
            </div>
            <div>
              {value.postText}
            </div>
            <div>
              posted by:{' '} <Link to={`/profile/${value.UserId}`}>{value.username} </Link>
            </div>
            <div>
            on {value.createdAt.toString().substring(0,10)}
            </div>
          </div>

          <div className="postListTwo"
            onClick={() => {
                  history(`/post/${value.id}`);
                }}
          >

          {value.postLink ?
            <iframe
              className="postListFrame"
              width="450" height="253"
              src={`https://www.youtube.com/embed/${value.postLink}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
              title="YouTube video player"
              allow="autoplay"
              frameBorder="0">

            </iframe> :
            '[ No media ]'
          } <br />

          </div>

          <div className="postListThree">

            <div style={{cursor:'pointer'}} className={likedPosts.includes(value.id) ? "unlikeBttn" : "likeBttn"}
              onClick={likedPosts.includes(value.id) ?
                ()=>alert('You already liked this')
                  :
                    () => {
                          if(! auth.username){
                            alert('must sign in to vote')
                          } else{
                            likeAPost(value.id);
                          }
                  }}
            >
            <ArrowDropUpIcon />
            </div>

            <div style={{textAlign:'center'}}>{value.Likes.length}</div>

            <div style={{cursor:'pointer'}} className={likedPosts.includes(value.id) ? "unlikeBttn" : "likeBttn"}
              onClick={likedPosts.includes(value.id) ? () => {
                    if(! auth.username){
                      alert('must sign in to vote')
                    } else{
                      likeAPost(value.id);
                    }
                  } :
                  () => {
                        if(! auth.username){
                          alert('must sign in to vote')
                        } else{
                          alert('can only like or unlike your liked post')
                        }
                      }
                }
            > <ArrowDropDownIcon />
            </div>
            <br />

            <div className="likedPost" style={{textAlign:'center'}}>
            {likedPosts.includes(value.id) ?
               <ThumbUpAltIcon/>:
              ''
            } <br />
            {likedPosts.includes(value.id) ?
               'You liked this':
              ''
            }
            </div>

          </div>

          <br />

        </div>
      );
    })}

    </div>
  )

}

export default PostsList
