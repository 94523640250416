import React, { useEffect, useState } from "react";
import '../css/Profile.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from '../api/axios'
import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout'

function Profile() {
  let { id } = useParams();
  let history = useNavigate();
  const [username, setUsername] = useState("");
  const [promos, setPromos] = useState(null);
  const [locate, setLocate] = useState(null);
  const [listOfPosts, setListOfPosts] = useState([]);
  const [listOfLocations, setListOfLocations] = useState([]);
  const [showPosts, setShowPosts] = useState(true)
  const { auth } = useAuth();
  const logout = useLogout()

  const profileInfo =()=>{
    axios.get(`/auth/basicinfo/${id}`).then((response) => {
      try{
        setUsername(response.data.username)
        setLocate(response.data.locate)
        setPromos(response.data.promos)
      }
      catch (err){
        alert("profile doesn't exist")
        history('/')
      }
    });
  }

  const profilePosts=()=>{
    axios.get(`/posts/byuserId/${id}`).then((response) => {
      setListOfPosts(response.data);
    });
  }

  const profileLocations=()=>{
    axios.get(`/locations/byuserId/${id}`).then((response) => {
      setListOfLocations(response.data);
    });
  }

  const deleteAccount = (id) => {
    axios
      .delete(`/auth/delete/${id}`, {
        headers: {
          accessToken: auth.accessToken
        },
      })
      .then(() => {
        //log out account
        logout()
        history('/');
      });
  };

  const updateLocate = () => {
    axios
      .put(
        "/auth/updatelocate",
        {
          id: id,
          locate: !locate
        },
        {
          headers: {
            accessToken: auth.accessToken
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          //used to console.log
        } else {
          alert(response.data)
          profileInfo()
        }
      });
  };

  const updatePromos = () => {
    axios
      .put(
        "/auth/updatepromos",
        {
          id: id,
          promos: !promos
        },
        {
          headers: {
            accessToken: auth.accessToken
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          //used to console.log
        } else {
          alert(response.data)
          profileInfo()
        }
      });
  };

  useEffect(() => {
    profileInfo()

    profilePosts()

    profileLocations()

  }, []);

  return (
    <div className="profilePageContainer">
      <div className="profileInfo">
        {" "}
        <h1> {username}'s profile </h1>
        {auth.username === username && (

          <div>
          <button
            onClick={()=>{if(window.confirm('Do you really want to DELETE your ACCOUNT?')){
              deleteAccount(auth.id)
              alert('account deleted')
            }

            }}
            className="deleteCommentButton"
          >
            {" "}
            DELETE ACCOUNT
          </button> <br /> <br />

          <button
            onClick={() => {
              history("/changepassword");
            }}
            className="logoutButton"
          >
            {" "}
            CHANGE PASSWORD
          </button>

          <br /><br />
          {locate ?
            <button
              onClick={() => {
                updateLocate()
              }}
              className="deleteCommentButton"
            >
              {" "}
              DISABLE LOCATION SERVICES
            </button>
          : <button
            onClick={() => {
              updateLocate()
            }}
            className="loginButton"
          >
            {" "}
            ENABLE LOCATION SERVICES
          </button>
          }

          <br /><br />
          {promos ?
            <button
              onClick={() => {
                updatePromos()
              }}
              className="deleteCommentButton"
            >
              {" "}
              DISABLE PROMO EMAILS
            </button>
          : <button
            onClick={() => {
              updatePromos()
            }}
            className="loginButton"
          >
            {" "}
            ENABLE PROMO EMAILS
          </button>
          }

          </div>

        )}

        <br /><br />

        {showPosts ?

          <button onClick={()=>setShowPosts(!showPosts)} className="loginButton">
          SEE LOCATIONS
          </button>

          :

          <button onClick={()=>setShowPosts(!showPosts)} className="loginButton">
          SEE POSTS
          </button>

        }

      </div>

    {showPosts ?

      <div className="profilePosts">
        <h2>Posts ({listOfPosts.length})</h2>
        {listOfPosts.map((value, key) => {
          return (
            <div key={key} className="profilePostWrapper" onClick={() => {
              history(`/post/${value.id}`);
            }}
            >
              <div className="profilePostsInfo">
                {value.postTitle} <br />
                {value.postText} <br />
                location: {' '}
                <Link to={`/location/${value.LocationId}`}>{value.LocationId} </Link>
                <br />
                added on {value.createdAt.substring(0,10)}<br />
                {value.Likes.length} likes
              </div>

              <div className="profilePostsMedia" style={{cursor:'pointer'}}>
                { value.postLink ?
                  <iframe
                    className="profilePostFrame"
                    width="560" height="315"
                    src={`https://www.youtube.com/embed/${value.postLink}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
                    title="YouTube video player"
                    allow="autoplay"
                    frameBorder="0">
                  </iframe> :

                  '[ No media ]'
                }

              </div>
              <div className="profilePostsFooter">

              </div>
            </div>
          );
        })}
      </div>

    :

    <div className="profilePosts">
      <h2>Locations ({listOfLocations.length > 20 ? 20 + '+' : listOfLocations.length })</h2>
      {listOfLocations.map((value, key) => {
        return (
          <div key={key} className="profilePostWrapper" onClick={() => {
            history(`/location/${value.id}`);
          }}
          >
            <div className="profilePostsInfo">
              {value.locationName} <br />
              {value.locationDesc} <br />
              location: {' '}
              <Link to={`/location/${value.id}`}>{value.id} </Link>
              <br />
              added on {value.createdAt.substring(0,10)}<br />
              <br />
            </div>

            <div className="profilePostsMedia" style={{cursor:'pointer'}}>
              { value.locationLink ?
                <iframe
                  className="profilePostFrame"
                  width="560" height="315"
                  src={`https://www.youtube.com/embed/${value.locationLink}?start=0&autoplay=0&loop=1&rel=0&controls=1&mute=0`}
                  title="YouTube video player"
                  allow="autoplay"
                  frameBorder="0">
                </iframe> :

                '[ No media ]'
              }

            </div>
            <div className="profilePostsFooter">

            </div>
          </div>
        );
      })}
    </div>

  }

    </div>
  );
}

export default Profile;
