import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link, Navigate } from 'react-router-dom';
import axios from '../api/axios'
import '../css/FrontPage.css'
import Trending from './Trending'
import Headlines from './Headlines'

const FrontPage = (props) => {

  const history = useNavigate();

  const [listOfPosts, setListOfPosts] = useState([]);
  const [trendingLocs, setTrendingLocs] = useState([]);
  const [newLocations, setNewLocations] = useState([]);
  const [proUsers, setProUsers] = useState([]);
  const [proLocos, setProLocos] = useState([]);
  const [newPosts, setNewPosts] = useState([]);
  const [locosList, setLocosList] = useState([])

  const getPosts = () => {
      axios.get('/posts/trending').then((response) => {
        setListOfPosts(response.data.listOfPosts);
      })
  };

  const trendingLocations = () => {
      axios.get('/locations/trending').then((response) => {
        setTrendingLocs(response.data.listOfLocations)
      })
  };

  const newestLocations = () => {
      axios.get('/locations/newest').then((response) => {
        setNewLocations(response.data.listOfLocations)
      })
  };

  const prolificUsers = () => {
      axios.get('/auth/prolific').then((response) => {
        setProUsers(response.data.listOfUsers)
      })
  };

  const newestPosts = () => {
      axios.get('/posts/newest').then((response) => {
        setNewPosts(response.data.listOfPosts)
      })
  };

  const getLocos = () => {
    axios.get("/auth/locos").then((response) => {
      setLocosList(response.data.listOfUsers);
    });
  };

  useEffect(()=>{
    getPosts()
    trendingLocations()
    newestLocations()
    prolificUsers()
    newestPosts()
    getLocos()
  }, [])

  return (

    <div>

      <Headlines
        trendingLocs={trendingLocs}
        newLocations={newLocations}
        proUsers={proUsers}
        newPosts={newPosts}
        locosList={locosList}
      />

      <div className="frontPageWrapper">

        <div className="frontPageOne">

          <h2> Trending Locations</h2>

        {trendingLocs && trendingLocs.map((val,key)=>{
          return(

            <div key={key} style={{padding: '5px'}}
              onClick={() => {
                    history(`/location/${val.id}`);
                  }}
            >
              <h3 style={{display:'inline'}}>
              <div style={{color:'black'}}>
                {val.locationName}
              </div>
              </h3>
              <div>Category:{' '}{val.category}</div>
              <div style={{color:'black'}}>
                Features:{' '}{val.locationDesc}
              </div>
              <div>added by {' '}{val.username}</div>
              <div>on {' '}{val.createdAt.substring(0,10)}</div>

            </div>
          );
        })}

           </div>

            <div className="frontPageTwo">

                <Trending listOfPosts={listOfPosts} />

            </div>

      </div>

    </div>

  );
}

export default FrontPage;
