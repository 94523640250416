import React from 'react';
import '../css/Policy.css'
import { Link } from 'react-router-dom';

const Contact = ()=>{
  return(
    <div className="policyHolder">
      <h1 id="">Contact Us</h1>

      <div style={{textAlign:'center', fontSize:'1.25em'}}>
      <p>
        Please send all inquires to:
      </p>
      <a style={{color:'black'}} href="mailto:inquires@openskateboardingmap.com" target="_blank" rel="noopener noreferrer">inquires@openskateboardingmap.com</a>
      </div>
    </div>
  )

}

export default Contact
